module.exports = [
    // { name: "AMC", url: "http://38.108.93.27/port/AMC/video.m3u8", play: false, },
    // { name: "BBC Am", url: "http://23.178.0.20:501/en/bbcamerica/index.m3u8", play: false, },
    // { name: "BBC W", url: "http://23.178.0.20:501/en/bbcworldnews/index.m3u8", play: false, },
    // { name: "BER-SWM", url: "https://bwenc2.ls3solutions.ca/swim/swim.m3u8", play: true, },
    // { name: "BET", url: "http://23.178.0.20:501/en/bet/video.m3u8", play: false, },
    { name: "BW Info", url: "https://s3.amazonaws.com/video.bluewavetv.promos/store+presentation+2018+-+5.mp4", play: true, },
    // { name: "BW-PL", url: "http://69.57.222.151:8082/playout/91/stream.m3u8", play: false, },
    // { name: "BW TV", url: "https://s3.amazonaws.com/video.bluewavetv.promos/store+presentation+tv+channels+dec+2018.mp4", play: true, },
    // { name: "Bounce", url: "https://bwenc2.ls3solutions.ca/bounce/bounce.m3u8", play: true, },
    // { name: "CBC", url: "https://bwenc2.ls3solutions.ca/cbchd/cbchd.m3u8", play: true, },
    // { name: "Charge", url: "https://bwenc2.ls3solutions.ca/chargetv/chargetv.m3u8", play: true, },
    // { name: "City TV", url: "https://bwenc2.ls3solutions.ca/citytv/citytv.m3u8", play: true, },
    // { name: "CNBC", url: "http://23.178.0.20:502/cnbc/cnbc.m3u8", play: false, },
    // { name: "CNN", url: "https://bwenc2.ls3solutions.ca/cnnhd/cnnhd.m3u8", play: true, },
    // { name: "Comet", url: "https://bwenc2.ls3solutions.ca/comettv/comettv.m3u8", play: true, },
    // { name: "CTV", url: "https://bwenc2.ls3solutions.ca/ctvhd/ctvhd.m3u8", play: true, },
    // { name: "CTV 2", url: "https://bwenc2.ls3solutions.ca/ctvtwo/ctvtwo.m3u8", play: true, },
    // { name: "DISC", url: "https://bwenc2.ls3solutions.ca/discoverychannel/discoverychannel.m3u8", play: true, },
    // { name: "DIY", url: "https://bwenc2.ls3solutions.ca/diy/diy.m3u8", play: true, },
    // { name: "Escape", url: "https://bwenc2.ls3solutions.ca/escape/escape.m3u8", play: true, },
    // { name: "Food", url: "https://bwenc2.ls3solutions.ca/foodhd/foodhd.m3u8", play: true, },
    // { name: "FOXNews", url: "http://23.178.0.20:501/port/FOXNEWS/index.m3u8", play: false, },
    // { name: "FX Sp", url: "https://bwenc2.ls3solutions.ca/foxsoccer/foxsoccer.m3u8", play: true, },
    // { name: "FoxS TV", url: "http://23.178.0.20:501/en/foxsports1/index.m3u8", play: false, },
    // { name: "FOXWUTV", url: "https://bwenc2.ls3solutions.ca/wutv/wutv.m3u8", play: true, },
    // { name: "Golf", url: "http://23.178.0.20:502/golf/golf.m3u8", play: false, },
    // { name: "GVX", url: "https://bwenc2.ls3solutions.ca/gvxhd/gvxhd.m3u8", play: true, },
    // { name: "GVMV", url: "https://bwenc2.ls3solutions.ca/gvmv/gvmv.m3u8", play: true, },
    // { name: "GVTV", url: "https://bwenc2.ls3solutions.ca/gvtvhd/gvtvhd.m3u8", play: true, },
    // { name: "GVVF", url: "https://bwenc2.ls3solutions.ca/gvvhd/gvvhd.m3u8", play: true, },
    // { name: "Heroes and Icons", url: "https://bwenc2.ls3solutions.ca/heroesandicons/heroesandicons.m3u8", play: true, },
    // { name: "HISTORY", url: "https://bwenc2.ls3solutions.ca/history/history.m3u8", play: true, },
    // { name: "HGTV", url: "https://bwenc2.ls3solutions.ca/hgtv/hgtv.m3u8", play: true, },
    // { name: "IONLife", url: "https://bwenc2.ls3solutions.ca/ionlife/ionlife.m3u8", play: true, },
    // { name: "Ion TV", url: "https://bwenc2.ls3solutions.ca/iontv/iontv.m3u8", play: true, },
    // { name: "Justice", url: "https://bwenc2.ls3solutions.ca/justicetv/justicetv.m3u8", play: true, },
    // { name: "KTLA", url: "https://bwenc2.ls3solutions.ca/ktla/ktla.m3u8", play: true, },
    // { name: "LaffTV", url: "https://bwenc2.ls3solutions.ca/lafftv/lafftv.m3u8", play: true, },
    // { name: "Life", url: "https://bwenc2.ls3solutions.ca/lifetime/lifetime.m3u8", play: true, },
    // { name: "MeTV", url: "https://bwenc2.ls3solutions.ca/metv/metv.m3u8", play: true, },
    // { name: "MSNBC", url: "http://23.178.0.20:502/msnbc/msnbc.m3u8", play: true, },
    // { name: "NBC", url: "https://bwenc2.ls3solutions.ca/nbcdetroit/nbcdetroit.m3u8", play: true, },
    // { name: "OUTDOOR", url: "https://bwenc2.ls3solutions.ca/olnhd/olnhd.m3u8", play: true, },
    // { name: "PBSC", url: "https://bwenc2.ls3solutions.ca/pbsc/pbsc.m3u8", play: true, },
    // { name: "PBS Kids", url: "https://bwenc2.ls3solutions.ca/pbskids/pbskids.m3u8", play: true, },
    // { name: "PBS Miami", url: "https://bwenc2.ls3solutions.ca/pbsmiami/pbsmiami.m3u8", play: true, },
    // { name: "Qubo Kids", url: "https://bwenc2.ls3solutions.ca/qubosd/qubosd.m3u8", play: true, },
    // { name: "RT USA", url: "https://rt-usa.secure.footprint.net/1105.m3u8", play: true, },
    // { name: "RTSpain", url: "https://bwenc2.ls3solutions.ca/rttwo/rttwo.m3u8", play: true, },
    // { name: "RTDOC", url: "https://bwenc2.ls3solutions.ca/rtdoc/rtdoc.m3u8", play: true, },
    // { name: "The CW", url: "https://bwenc2.ls3solutions.ca/thecw/thecw.m3u8", play: true, },
    // { name: "The Weather Channel", url: "http://23.178.0.20:501/en/weatherus/index.m3u8", play: true, },
    // { name: "TLCHD", url: "http://23.178.0.20:501/en/tlc/index.m3u8", play: true, },
    // { name: "TVO", url: "https://bwenc2.ls3solutions.ca/tvo/tvo.m3u8", play: true, },
    // { name: "WGN Chicago", url: "https://bwenc2.ls3solutions.ca/wgnchicago/wgnchicago.m3u8", play: true, },
    // { name: "WPIX NY", url: "https://bwenc2.ls3solutions.ca/wpixny/wpixny.m3u8", play: true, },
    // { name: "WSVNFOX", url: "https://bwenc2.ls3solutions.ca/wsvnfox/wsvnfox.m3u8", play: true, },
    // { name: "WWORNY", url: "https://bwenc2.ls3solutions.ca/wwor/wwor.m3u8", play: true, },
    // { name: "Yes", url: "https://bwenc2.ls3solutions.ca/yestv/yestv.m3u8", play: true, },
    // { name: "ZBM-CBS", url: "https://bwenc2.ls3solutions.ca/zbmcbs/zbmcbs.m3u8", play: true, },
    // { name: "ZFB-ABC", url: "https://bwenc2.ls3solutions.ca/abchd/abchd.m3u8", play: true, },
];