
let videos = require("./urls");

document.addEventListener("DOMContentLoaded", event => {

  let config = {
    vidAttribs: [
      // [ "controls", "false" ],
      [ "width", "428" ],
      // [ "width", "214" ],
      [ "height", "240" ],
      // [ "height", "120" ],
      [ "muted", "true" ],
    ],
  };

  for (let i=0; i<videos.length; i++) {
    if (videos[i].play) {
      const currentNode = document.createElement('video');
  
      currentNode.id = `video${i}`;
      currentNode.classList = "video-js";
      currentNode.setAttribute("src", videos[i].url);
  
      const attribs = config.vidAttribs;
      for (let n=0; n<attribs.length; n++) {
        currentNode.setAttribute(attribs[n][0], attribs[n][1]);
      }
  
      document.body.appendChild(currentNode);
  
      videos[i].elem = document.getElementById(`video${i}`);
      videojs(`video${i}`);
    }

  }

  console.log(videos);
});